import { t } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import makeRequest from "../pages/api/config-request";
import { urlList } from '../pages/api/config';
import { PlayListContext } from '../Session';
import RingPhoneItem from './RingPhoneItem';
import axios from 'axios';

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
};

function RingPhone({ onBack, currentSong }) {

    const playList = useContext(PlayListContext);
    const [listCRBT, setListCRBT] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(async () => {
        try {
            setIsLoading(true);
            const req = await makeRequest("get", urlList().URL_CRBT.LIST, {
                timestamp: new Date().getTime(),
                songId: currentSong.id,
                page: 0,
                size: 10,
                input: ""
            }, {
                userId: localStorage.getItem('userId') || '1',
                token: localStorage.getItem('token') || '1',
                "Accept-language": playList.language,
                "Client-Type": "Web",
                Revision: 1
            })

            // const req = await fetch(urlList().URL_CRBT.LIST + `?timestamp=${new Date().getTime()}&songId=${currentSong.id}&page=0&size=10`, {
            //     method: 'GET',
            //     headers: {
            //         userId: localStorage.getItem('userId') || '1',
            //         token: localStorage.getItem('token') || '1',
            //         "Accept-language": playList.language,
            //         "Client-Type": "Android",
            //         Revision: 1
            //     },
            // })

            // console.log(response.data);
            if (req.status == 200) {
                setListCRBT(req.data.data);
            }
            console.log(req)
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [])

    console.log("RingPhone", currentSong);
    if (!isMobile.Android() && !isMobile.iOS()) {
        return (<div>
            web
        </div>)
    }

    return (
        <div className="ring-phone-fullscreen">
            <div className="ringme-phone-fullscreen__container">
                <div className="ringme-phone__header">
                    <div className="ringme-phone__header--back">
                        <img onClick={() => onBack(false)} src={require('../assets/icon/ic_back_black.png')} />
                    </div>
                    <span>{t('Ring Phone')}</span>
                </div>
                <div className="ringme-phone__content">
                    {listCRBT?.length
                        ? listCRBT.map(i => <RingPhoneItem data={currentSong} key={currentSong.id} />)
                        : <RingPhoneItem data={currentSong} />
                    }
                </div>
            </div>
        </div>
    )
}

export default RingPhone
