import React from "react";

const PlayListContext = React.createContext({
    playList: null,
    isPlaying: null,
    idPlaylist: null,
    idSong : null,
    onPlay: null,
    loadLike:null,
    currentMusicIndex:null,
    loadSideBar:null,
    language: 'lo',

});

export default PlayListContext