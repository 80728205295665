import React, { useEffect, Component, useState, useContext } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import makeRequest from "../pages/api/config-request";
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import Dropdown from 'react-multilevel-dropdown';
import { useAlert } from 'react-alert'
import axios, { post } from 'axios';
import {
    Grid,
    Button,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    InputAdornment,
    Toolbar
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Search from '@material-ui/icons/Search';
import './style.css';
import { Menu, MenuItem, TextField } from "@mui/material";
import { urlList } from "../pages/api/config";
import { PlayListContext } from "../Session";

const AppBar = styled(MuiAppBar)`
  height: 100%;
  background: #121212;
  display: flex;
  flex-direction: row;
  padding: 1%;
  width: 100%;
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const CssTextField = styled(TextField)({

    '& .MuiOutlinedInput-input': {
        color: 'white !important',
        borderRadius: '50rem',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#484848',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '1rem',
        height: '100%',
        backgroundColor: '#484848',
        '&.Mui-focused fieldset': {
            borderColor: '#484848',
            border: 'unset'
        },
    },

});


function LanguageMenu({ history, location, firebase }) {
    const playList = useContext(PlayListContext)
    const [menuNotify, setMenuNotify] = useState(null);
    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const openNotify = Boolean(menuNotify);
    const [language, setLanguage] = useState(playList.language);
    const { t, i18n } = useTranslation();
    const handleLangChange = (type) => {
        playList.setLanguage(type)
        i18n.changeLanguage(type);
    };

    useEffect(async () => {


    }, [])


    const toggleNotify = async (event) => {
        setMenuNotify(event.currentTarget);
    };
    const closeNotify = async () => {
        setMenuNotify(null);

    };

    return (
        <>

            {/* <Button
                id="basic-button"
                className="btn-header"
                variant="contained"
                style={{ borderRadius: '50rem', marginLeft: '10px', backgroundColor: '#282828', color: 'white' }}
             
                endIcon={<KeyboardArrowDownIcon />}
            >{t("language")}
            </Button> */}
            <img style={{ float: 'right', height: '80%', width: 'auto', marginBottom: 'auto', marginTop: 'auto', marginLeft: '10px', marginRight: '10px' }} src={require('../assets/language.png')}
                onClick={(e) => { toggleNotify(e) }} />
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                id="basic-menu"
                className="header-laguage"
                anchorEl={menuNotify}
                open={openNotify}
                onClose={closeNotify}
            >

                <MenuItem
                    onClick={() => {
                        handleLangChange("lao")
                    }}

                >
                    Lao


                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleLangChange("en")
                    }}

                >
                    EN


                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleLangChange("vn")
                    }}

                >
                    VN


                </MenuItem>


            </Menu>
        </>
    );
}





function UserMenu(props) {
    const { history, location, firebase } = props;

    const [anchorMenu, setAnchorMenu] = useState(null);
    const { t } = useTranslation();

    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const phone = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const open = Boolean(anchorMenu);
    const toggleMenu = event => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const onSignout = () => {
        localStorage.setItem('userId', '');
        localStorage.setItem('phoneNumber', '');
        localStorage.setItem('email', '');
        localStorage.setItem('token', '');
        localStorage.setItem('userName', '');
        localStorage.setItem('avatar', '');
        localStorage.setItem('tokenExpired', '');
        window.location.href = '/'
        window.location.reload();
    }

    const onGotoInfor = () => {
        // this.props.currentprops.history.push('/infor')
        window.open('/infor')
    }



    return (
        <>
            <img src={require('../assets/icon/user.png')} style={{ height: '50%', float: 'right', width: 'auto', marginTop: '1.25vh' }} onClick={(e) => { toggleMenu(e) }} />
            <Menu
                id="menu-appbar"
                className="header"
                anchorEl={anchorMenu}
                open={open}
                onClose={() => { closeMenu() }}
            >

                <MenuItem
                    onClick={() => {
                        onGotoInfor();
                    }}
                >
                    {t('infor')}
                </MenuItem>
                <MenuItem
                    onClick={() => {

                        window.open('https://mp3.zing.vn/tnc')
                    }

                    }

                >
                    {t('policy')}


                </MenuItem>
                <MenuItem


                >
                    {t('upgrade-to-vip')}


                </MenuItem>
                <MenuItem
                    onClick={() => onSignout()}
                >

                    {t('logout')}

                </MenuItem>
            </Menu>
        </>
    );

}

function HeaderMobile(props) {
    const { history, location, firebase, funtionOpenDrawer } = props;

    const [search, setSearch] = useState('');
    const [top, setTop] = useState(null);
    const { t } = useTranslation();
    const alert = useAlert();
    const playList = useContext(PlayListContext);

    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const phone = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'

    useEffect(async () => {
        await showData();
        if (history.location.pathname == '/search') {
            setSearch(queryString.parse(location.search).search)
        }
    }, [])




    const onclickSearch = (search) => {
        history.push({
            pathname: '/search',
            search: '?search=' + search
        });
    }
    const showData = async () => {
        const param = {
            timestamp: new Date().getTime(),
        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("get", urlList().URL_SEARCH.TOP, param, headers, {
        })
            .then(({ data }) => {
                if (data) {
                    setTop(data.topKeyword)
                }

            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);
            });
    }

    const toSignIn = () => {
        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + window.location.origin + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://umusic.ringme.vn/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://api.umusic.la/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        window.location.href = 'https://sso.laoid.net/login?client_id=d64d2475-01eb-4330-b8d1-ec27d87b1ba5&redirect_uri=http://api.umusic.la&use_callback_uri=true'

    }
    return (
        <div className="header-main-mobile">
            <AppBar position="sticky" elevation={0} >

                <img className='img-menu-bar' src={require('../assets/menu-bar.png')} style={{ height: '40%', width: '5%', marginTop: '1.5vh', marginLeft: '5%' }} onClick={() => { funtionOpenDrawer() }} />

                <div style={{
                    height: '40%', width: '70%', marginTop: '1.5vh'
                }}

                >
                    {history.location.pathname == '/search' ?

                        <CssTextField
                            placeholder={t('searching')}
                            id="filled-multiline-static"
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onClick={() => { showData() }}
                            style={{
                                backgroundColor: '#484848',
                                color: 'white',
                                borderRadius: '50rem',
                                height: '100%',
                                width: '86%',
                                marginLeft:'14%'
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    onclickSearch(e.target.value);
                                }
                            }}
                            autoComplete='off'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: 'white' }} />
                                    </InputAdornment>
                                ),

                                endAdornment: (



                                    <InputAdornment position="end" >
                                        {
                                            (search != '') ?
                                                <div style={{ color: 'white', cursor: 'pointer' }} onClick={() => { setSearch('') }}>
                                                    X
                                                </div>
                                                : ''
                                        }


                                    </InputAdornment>




                                ),
                            }}
                        />

                        : <img src={require('../assets/Logomain.png')} style={{ width: 'auto', height: '100%', marginLeft: '42%' }} onClick={() => {
                            history.push({
                                pathname: '/'
                            })
                        }} />

                    }

                </div>

                <div style={{ width: '20%', height: '40%', marginTop: '1.5vh' }}>
                    {token != '1' ?
                        ''

                        :


                        <div style={{ height: 'auto', display: 'block' }}>
                            <Button
                                variant="contained"
                                className="btn-header-mobile"

                                style={{ float: 'right', borderRadius: '50rem', backgroundColor: 'white', color: 'black', margin: 'auto' }}
                                onClick={() => toSignIn()}
                            >
                                {t('login')}
                            </Button>


                        </div>

                    }

                </div>
            </AppBar >
        </div >

    )
}


const SignUp = compose(
    withRouter
)(HeaderMobile);

export default SignUp;
