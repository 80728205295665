import request from 'request-promise';
import React, { useContext } from 'react';
import makeRequest from "./config-request";
import { PlayListContext } from '../../Session';



// const Domain = "http://10.20.0.1:8089/umusic-service/"
// const Domain = "http://musicapi.ringme.vn:8080/umusic-service/"
// const Domain = "http://192.168.1.57:8089/"
const Domain = "https://api.umusic.la/umusic-service/"
// export const Domain = "http://localhost:8089/"
// const Domain2 = "http://laomusicapi.ringme.vn/umusic-service/"
export function urlList() {
    return {

        "URL_AUTHEN": {
            "LOGIN_PHONE": Domain + "v1/authen/login/request-otp",
            "CHECK_OTP": Domain + "v1/authen/login/otp",
            "LOGIN_EMAIL": Domain + "v1/authen/login/email",
            "REGISTER": Domain + "v1/authen/regis",
            "FORGOTPASS": Domain + "v1/authen/forgot",
            "UNID": Domain + "v1/authen/login/uni-id-code",
        },

        "URL_DISCOVERY": {
            "CATE_LIST" : Domain + "v1/new/cate-list",
            // "ALL_MUSIC" : Domain + "v1/web/home",
            "ALL_SERVICE": Domain + "/public-api/v1/employees/services/all",
            "SERVICE_SEARCH": Domain + "/public-api/v1/employees/services/search",
            "PAUSE": Domain + "/public-api/v1/employees/services/pause",
            "UID_SCAN_HISTORY": Domain + "/public-api/v1/employees/uid-scan-history/all",
            "RESUM": Domain + "/public-api/v1/employees/services/resume-by-code",
            "REPORT": Domain + "/public-api/v1/employees/services/report-by-code",
            "PAUSE_SERVICE_WARRANTY": Domain + "/public-api/v1/employees/services/pause-service-warranty",
            "BANNER" : Domain + "v1/banner/list",
            "RECOMMEND": Domain + "v1/song/recommend"
        },
        "URL_PERSONAL": {
            "LIKE_SONG": Domain + "v1/social/song/liked/list",
            "PLAYLIST": Domain + "v1/social/playlist/liked/list",
            "ARTIST": Domain + "v1/social/artist/liked/list",
            "ALBUMS": Domain + "v1/social/album/liked/list",
            "OWNER_PLAYLIST": Domain + "v1/user/playlist/list",
            "CREATE_USER_PLAYLIST": Domain + "v1/user/playlist/create",
            "ADD_SONG": Domain + "v1/user/playlist/add-song",
            "DELETE_USER_PLAYLIST": Domain + "v1/user/playlist/delete",
            "REMOVE_SONG_USER_PLAYLIST": Domain + "v1/user/playlist/remove-song",
            "UPLOAD_FILE": Domain + "v1/media/mp3/upload",
            "POST_FILE": Domain + "v1/user/song/post",
            "UPLOAD_LIST": Domain + "v1/user/song/list",
        },
        "URL_NEW": {
            "ALL": Domain + "v1/new/song-list",


        },
        "URL_RANK": {
            "ALL": Domain + "v1/ranking/cate-list",
            "DETAIL": Domain + "v1/ranking/cate/detail",

        },
        "URL_TYPE": {
            "ALL": Domain + "v1/genre/collection-list",
            "DETAIL": Domain + "v1/genre/collection/detail",
            "COLLECTION": Domain + "v1/personal/collection-list",
        },
        "URL_ALBUM": {
            "DETAIL": Domain + "v1/album/info",
            "LIST_SONG": Domain + "v1/album/song/list",
            "LIKE": Domain + "v1/social/album/like",
            "ALBUM_RELATE": Domain + "v1/album/related",
            "PLAYLIST_RELATE": Domain + "v1/album/related/playlist",
            "LIST_CMT": Domain + "v1/social/album/comment/list",
            "POST_CMT": Domain + "v1/social/album/comment/post",
            "LIKE_CMT": Domain + "v1/social/album/comment/like",
            "DELETE": Domain + "v1/social/album/comment/delete",
        },
        "URL_PLAYLIST": {
            "DETAIL": Domain + "v1/playlist/info",
            "LIST_SONG": Domain + "v1/playlist/song/list",
            "LIKE": Domain + "v1/social/playlist/like",
            "PLAYLIST_RELATE": Domain + "v1/playlist/related",
            "LIST_CMT": Domain + "v1/social/playlist/comment/list",
            "POST_CMT": Domain + "v1/social/playlist/comment/post",
            "LIKE_CMT": Domain + "v1/social/playlist/comment/like",
            "DELETE": Domain + "v1/social/playlist/comment/delete",
            "SONG_RELATE": Domain + "v1/song/related",

        },
        "URL_SINGER": {
            "DETAIL": Domain + "v1/artist/info",
            "LIST_SONG": Domain + "v1/artist/song/list",
            "LIST_ALBUM": Domain + "v1/artist/album/list",
            "LIST_PLAYLIST": Domain + "v1/artist/playlist/list",
            "CMT_PROFILE": Domain + "v1/social/artist/comment/list",
            "LIKE": Domain + "v1/social/artist/like",
            "POST": Domain + "v1/social/artist/comment/post",
            "LIKE_CMT": Domain + "v1/social/artist/comment/like",
            "DELETE": Domain + "v1/social/artist/comment/delete",




        },
        "URL_AUTHOR": {
            "DETAIL": Domain + "v1/author/info",
            "LIST_SONG": Domain + "v1/author/song/list",
            "LIST_ALBUM": Domain + "v1/author/album/list",
            "LIST_PLAYLIST": Domain + "v1/author/playlist/list",
            "CMT_PROFILE": Domain + "v1/social/author/comment/list",
            "LIKE": Domain + "v1/social/author/like",
            "POST": Domain + "v1/social/author/comment/post",
            "LIKE_CMT": Domain + "v1/social/author/comment/like",
            "DELETE": Domain + "v1/social/author/comment/delete",


        },

        "URL_SONG": {
            "LIKE": Domain + "v1/social/song/like",
            "CMT_LIST": Domain + "v1/social/song/comment/list",
            "POST_LIST": Domain + "v1/social/song/comment/post",
            "LIKE_CMT": Domain + "v1/social/song/comment/like",
            "LYRICS": Domain + "v1/song/info",
            "DELETE": Domain + "v1/social/song/comment/delete",

        },
        "URL_NOTIFY": {
            "LIST": Domain + "v1/notify/social"
        },
        "URL_SEARCH": {
            "TOP": Domain + "v1/search/top",
            "ALL": Domain + "v1/search/all",
            "GROUP": Domain + "v1/search/group",
            "RELATE": Domain + "v1/search/all/tittle",
        },
        "URL_LOG": {
            "DOWNLOAD": Domain + "v1/logging/downloading",
            "LISTEN": Domain + "v1/logging/listening",
            "SHARE": Domain + "v1/logging/sharing"
        },
        "URL_CRBT": {
            "LIST": Domain + "v1/crbt/list",
            "LIST_REGISTER": Domain + "v1/crbt/list/registered",
            "REGISTER": Domain + "v1/crbt/subscribe",
            "CANCEL_SERVICE": Domain + "v1/crbt/unSubscribe",
            "CANCEL_TONE": Domain + "v1/crbt/delInboxTone"
        }

    }
}

export async function logShare(url, id, userId, token, alert, t, type, link, shareTo) {

    let param = {
        timestamp: new Date().getTime(),
        contentId: id,
        contentType: type,
        shareTo: shareTo,
        link: link,
        mediaLink: url,
    }
    let headers = {
        userId: userId,
        token: token,
    }

    await makeRequest("post", urlList().URL_LOG.SHARE, param, headers, {
    })
}


export async function logDownload(url, id, userId, token, alert, t) {

    let param = {
        timestamp: new Date().getTime(),
        songId: id,
        mediaLink: url,
    }
    let headers = {
        userId: userId,
        token: token,
    }

    await makeRequest("post", urlList().URL_LOG.DOWNLOAD, param, headers, {
    })

}
