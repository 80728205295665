import React from "react";
import { connect } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import StartupState from './context/startup/StartupState'

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 1500,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE
}
function App({ theme }) {


  return (
    <StartupState>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <AlertProvider template={AlertTemplate} {...options}>
                <Routes />
              </AlertProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </StartupState>
  );
}
export default

  connect(store => ({ theme: store.themeReducer }))(App)

  ;
