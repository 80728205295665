

import async from "../components/Async";

const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Personal = async(() => import("../pages/modules/Personal"))
const New = async(() => import("../pages/modules/New"))
const Ratings = async(() => import("../pages/modules/Ratings"))
const Types = async(() => import("../pages/modules/Types"))
const Albums = async(() => import("../pages/modules/Albums"))
const Playlist = async(() => import("../pages/modules/Playlist"))
const MyPlaylist = async(() => import("../pages/modules/UserPlaylist"))
const Singer = async(() => import("../pages/modules/Singer"))
const Discovery = async(() => import("../pages/modules/Discovery"))
const Collection = async(() => import("../pages/modules/Collection"))
const Infor = async(() => import("../pages/modules/Infor"))
const Search = async(() => import("../pages/modules/Search"))
const SearchDetail = async(() => import("../pages/modules/SearchDetail"))
const Author = async(() => import("../pages/modules/Author"))
const RingPhonePage = async(() => import("../pages/modules/RingPhonePage"))
const RingPhoneManager = async(() => import("../pages/modules/RingPhoneManager"))
const Recommend = async(() => import("../pages/modules/Recommend"))





const collectionRoutes = {
    id: "Collection",
    path: "/collection",
    name: "Collection",
    component: Collection,
};
const songRoutes = {
    id: "Song",
    path: "/song",
    name: "Song",
    component: Discovery,
};
const userPlaylistRoutes = {
    id: "UserPlaylist",
    path: "/userPlaylist",
    name: "UserPlaylist",
    component: MyPlaylist,
};
const authorRoutes = {
    id: "Author",
    path: "/author",
    name: "Author",
    component: Author,
};
const searchRoutes = {
    id: "Search",
    path: "/search",
    name: "Search",
    component: Search,
};
const searchDetailRoutes = {
    id: "SearchDetail",
    path: "/search-detail",
    name: "SearchDetail",
    component: SearchDetail,
};


const discoveryRoutes = {
    id: 'Home',
    path: "/",
    icon: require("../assets/Group.png"),
    name: "Home",
    component: Discovery,
    containsHome: true,
    show: true,
};


const albumsRoutes = {
    id: "Albums",
    path: "/album",
    name: "Albums",
    component: Albums,

};

const playlistRoutes = {
    id: "Playlist",
    path: "/playlist",
    name: "Playlist",
    component: Playlist,
};

const singerRoutes = {
    id: "Singer",
    path: "/singer",
    name: "Singer",
    component: Singer,

};

const personalRoutes = {
    id: 'personal',
    path: "/personal",
    icon: require("../assets/icon/disc.png"),
    name: "Personal",
    component: Personal,
    show: true,
};


const newRoutes = {
    id: "new",
    path: "/new",
    icon: require("../assets/icon/new.png"),
    name: "New",
    component: New,
    show: true,
};


const ratingsRoutes = {
    id: 'rarking',
    path: "/ratings",
    icon: require("../assets/icon/rate.png"),
    name: "Ratings",
    component: Ratings,
    show: true,
};



const typesRoutes = {
    id: 'type',
    path: "/types",
    icon: require("../assets/icon/types.png"),
    name: "Types",
    component: Types,
    show: true,
};


const createPlayListRoutes = {
    id: 'create-playlist',
    path: "/create",
    icon: require("../assets/icon/plus.png"),
    name: "CreatePlayList",
};



const signInRoutes = {
    id: "Đăng nhập",
    path: "/sign-in",
    name: "Sign In",
    component: SignIn
};


const signUpRoutes = {
    id: "Đăng nhập",
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp
};

const InforRoutes = {
    id: "Infor",
    path: "/infor",
    name: "Infor",
    component: Infor,

};
const resetPassRoutes = {
    id: "Đăng nhập",
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword
};

const ringPhoneRouters = {
    id: "Ring Phone",
    path: "/ring-phone",
    name: "Ring Phone",
    component: RingPhonePage,
    icon: require("../assets/icon/ic_ring_phone.png"),
    show: true
}

const ringPhoneManagerRouters = {
    id: "Ring Phone Manage",
    path: "/ring-phone-manage",
    name: "Ring Phone Manage",
    component: RingPhoneManager,
    icon: require("../assets/icon/ic_ring_phone.svg"),
    show: true
}

const recommendRouters = {
    id: "Recommend",
    path: "/recommend",
    name: "Recommend",
    component: Recommend
}

export default [
    discoveryRoutes,
    newRoutes,
    ratingsRoutes,
    personalRoutes,
    typesRoutes,
    createPlayListRoutes,
    signInRoutes,
    signUpRoutes,
    albumsRoutes,
    playlistRoutes,
    singerRoutes,
    resetPassRoutes,
    InforRoutes,
    collectionRoutes,
    searchRoutes,
    searchDetailRoutes,
    authorRoutes,
    userPlaylistRoutes,
    songRoutes,
    ringPhoneRouters,
    ringPhoneManagerRouters,
    // recommendRouters
];
