import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import makeRequest from "../pages/api/config-request";

import { postApiNew, urlList } from "../pages/api/config.js";
import { NavLink as RouterNavLink, withRouter, Link } from "react-router-dom";
// import { darken } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";
import './style.css'
import './styleSidebar.css'

import {
  Grid,
  Chip,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  Divider as MuiDivider,
  List as MuiList,
  Typography,
  Button
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import routes from "../routes/index";
import Loader from 'react-loader-spinner';

import { Layers } from "react-feather";
import { PlayListContext } from "../Session";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";





const Scrollbar = styled(PerfectScrollbar)`
  background-color: #2a213a;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: #2a213a 
  ;
`;
const Divider = styled(MuiDivider)(spacing);

const Items = styled.div`
width : 90% ;
margin-left: auto;
margin-right : auto;
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;


const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${props => props.activeClassName} {
    background-color: #613f94;
  
    border-radius: 5px;
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    padding: 0 ${props => props.theme.spacing(4)}px;
    font-Family: Imported !important;
  }
`;

const Flag = styled.img`
  // border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;


const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;


const SidebarFooter = styled.div`
  background-color: red !important;
  padding: ${props => props.theme.spacing(3)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;



function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  const { t } = useTranslation();

  return (

    <Category {...rest}>

      <Flag src={icon} alt="Devices" />
      <CategoryText>{t(name)}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}


function MyPlaylist({
  row,
  name,
  onclickOpenPlaylist,
  ...rest
}) {
  return (
    <div className="my-playlist-sidebar"
      onClick={() => { onclickOpenPlaylist(row.id) }}
    >

      {name}
    </div>
  );
}


function Sidebar(props) {
  const alert = useAlert();
  const { t } = useTranslation();
  const playList = useContext(PlayListContext);

  const [userPlaylist, setUserPlaylist] = useState(null)
  const { classes, staticContext, functionHanldeOpen, loading, contextLoadSideBar, history, ...other } = props;
  const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1';
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1';
  const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1';

  const redirectLink = (link) => {
    if (link == '#') {
      window.open('https://www.autofarmer.me/', '_blank');
    }

  }

  useEffect(async () => {
    showData()

  }, [loading, contextLoadSideBar])

  const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
  ));



  const redirectLinkInstall = link => {
    window.open(link);
  }
  const showData = async () => {
    const param = {
      timestamp: new Date().getTime(),
      page: 0,
      size: 10,

    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,
    }
    await makeRequest("get", urlList().URL_PERSONAL.OWNER_PLAYLIST, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          setUserPlaylist(data.data)


        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }
  const redirectPlaylist = id => {
    history.push({
      pathname: '/userPlaylist',
      search: '?playlist=' + id
    });
  }
  const onclickRedirect = async e => {
    history.push({ pathname: '/' });
  }

  const onClickBuyVip = () => {
    if (token == '1') {
      // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://umusic.ringme.vn/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
      // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://api.umusic.la/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
      window.location.href = 'https://sso.laoid.net/login?client_id=d64d2475-01eb-4330-b8d1-ec27d87b1ba5&redirect_uri=http://api.umusic.la&use_callback_uri=true'
    } else {
      window.location.href = 'http://api.umusic.la/umusic-service/v1/pack_mps/res?msisdn=' + localStorage.getItem('phoneNumber') + '&lang=' + playList.language;
    }
  }

  return (



    <div className="side-bar-main">

      <div style={{
        marginLeft: '25px', color: 'white', fontSize: '26px', height: '10%', padding: '4%'

      }}
        onClick={onclickRedirect}
      >

        <img src={require('../assets/Logo3.png')} style={{ width: '60%', }} />
      </div>
      <div className="text-rainbow-animation"></div>

      <div style={{
        height: '90%',

      }}>

        {/* <div class="side-bar-animation">
        </div> */}

        <Scrollbar>
          <List>
            <Items>
              {routes.map((category, index) =>
              (

                <div key={category.name} style={{ fontFamily: "Imported !important" }}>

                  <>
                    {(index == 3 || index == 5) ? <Divider my={6} /> : null}

                    {category.name == 'CreatePlayList' && token != 1 ?
                      (
                        <React.Fragment key={index}>


                          <SidebarCategory
                            name={category.id}
                            onClick={functionHanldeOpen}
                            icon={category.icon}
                          />
                          <div className="my-playlist-main">
                            {token != 1 ?
                              <>
                                {
                                  userPlaylist && userPlaylist.map((rowChild, indexChild) => (

                                    <MyPlaylist
                                      key={indexChild}
                                      row={rowChild}
                                      name={rowChild.playlistName}
                                      onclickOpenPlaylist={redirectPlaylist}

                                      component={NavLink}
                                    />
                                  ))

                                }
                              </>
                              : ''
                            }
                          </div>


                        </React.Fragment>
                      )
                      : category.show ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isCollapsable={false}
                            name={category.id}
                            to={category.path}
                            onClick={() => redirectLink(category.path)}
                            activeClassName={category.path !== '#' ? 'active' : ''}
                            component={NavLink}
                            icon={category.icon}
                            badge={category.badge}
                            exact
                          />

                        </React.Fragment>
                      )
                        :
                        ''
                    }


                  </>
                </div>
              )
              )
              }
              <React.Fragment >

                <SidebarCategory
                  isCollapsable={false}
                  name={t('download-app')}
                  onClick={() => redirectLinkInstall('https://www.google.cm')}
                  icon={require("../assets/icon/download.png")}

                />


              </React.Fragment>
            </Items>


          </List>
          <div className="vip-banner-slidebar">
            <div className="vip-banner-des">{t('buy-vip-des')}</div>
            <a className="buy-vip-btn" onClick={() => onClickBuyVip()} href="#">
              {t('buy-vip')}
            </a>
          </div>

        </Scrollbar >

      </div>

    </div>

  );
}


export default withRouter(Sidebar);
