import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';
import App from './App';
import "./App.css";
import "./customer.css";
import { Provider } from 'react-redux';
import store from './redux/store/index';
import "./pages/language/i18n";


import * as serviceWorker from "./serviceWorker";
import app from './Firebase/firebaseConfig.js';
// Import the functions you need from the SDKs you need
app.analytics().logEvent("page_view");
// const analytics = getAnalytics(app);


const rootElement = document.getElementById('root')
ReactDOM.hydrate(
	<React.StrictMode>


		<Provider store={store}>
			<Suspense fallback="">
				<App />
			</Suspense>

		</Provider>
	</React.StrictMode>, rootElement
);


// serviceWorker.unregister();