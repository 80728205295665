import React, { useEffect, Component, useState, useContext } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";
import makeRequest from "../pages/api/config-request";
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import Dropdown from 'react-multilevel-dropdown';
import { useAlert } from 'react-alert'
import axios, { post } from 'axios';
import {
    Grid,
    Button,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    InputAdornment,
    Toolbar
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Search from '@material-ui/icons/Search';
import './style.css';
import { Menu, MenuItem, TextField } from "@mui/material";
import { urlList } from "../pages/api/config";
import { PlayListContext } from "../Session";

const AppBar = styled(MuiAppBar)`
  height: 100%;
  background: #170F23;
  display: flex;
  flex-direction: row;
  padding: 1%;
  width: 100%;
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const CssTextField = styled(TextField)({

    '& .MuiOutlinedInput-input': {
        color: 'white !important',
        borderRadius: '50rem',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#484848',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '1rem',
        height: '100%',
        backgroundColor: '#484848',
        '&.Mui-focused fieldset': {
            borderColor: '#484848',
            border: 'unset'
        },
    },

});


function LanguageMenu({ history, location, firebase }) {
    const playList = useContext(PlayListContext)
    const [menuNotify, setMenuNotify] = useState(null);
    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const openNotify = Boolean(menuNotify);
    const [language, setLanguage] = useState(playList.language);
    const { t, i18n } = useTranslation();
    const handleLangChange = (type) => {
        playList.setLanguage(type)
        i18n.changeLanguage(type);
    };

    useEffect(async () => {


    }, [])


    const toggleNotify = async (event) => {
        setMenuNotify(event.currentTarget);
    };
    const closeNotify = async () => {
        setMenuNotify(null);

    };
    return (
        <>
            <img style={{ float: 'right', height: '80%', width: 'auto', marginBottom: 'auto', marginTop: 'auto', marginLeft: '10px', marginRight: '10px' }} src={require('../assets/language.png')}
                onClick={(e) => { toggleNotify(e) }} />
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                id="basic-menu"
                className="header-laguage"
                anchorEl={menuNotify}
                open={openNotify}
                onClose={closeNotify}
            >

                <MenuItem
                    onClick={() => {
                        handleLangChange("lo")
                    }}
                    className={playList.language == 'lo' ? 'active' : ''}

                >
                    Lao


                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleLangChange("en")
                    }}
                    className={playList.language == 'en' ? 'active' : ''}
                >
                    EN


                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleLangChange("vi")
                    }}
                    className={playList.language == 'vi' ? 'active' : ''}
                >
                    VN


                </MenuItem>


            </Menu>
        </>
    );
}


function Setting() {
    const { t } = useTranslation();
    const alert = useAlert()
    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const [infoSong, setInfoSong] = useState(null);
    const playList = useContext(PlayListContext);

    const onChange = (e) => {
        if (e.target.files && e.target.files.length > 1) {
            for (let index = 0; index < e.target.files.length; index++) {
                uploadFile(e.target.files[index]);
            }
        }
        else {
            uploadFile(e.target.files[0]);

        }
    }
    const uploadFile = async (file) => {
        let formData = new FormData();
        formData.append("uFile", file);



        const config = {
            headers: {
                "Client-Type": "Web",
                "Accept": "*/*",
                "Revision": 1,
                "Accept-language": "en",
                userId: userId,
                token: token,
                'Content-Type': 'multipart/form-data'
            },
            params: {
                timestamp: new Date().getTime(),
                mpw: "9EBB7AE993E7FCDFA600E108CC21A259",


            }
        }


        await post(urlList().URL_PERSONAL.UPLOAD_FILE, formData, config)
            .then(({ data }) => {

                if (data) {

                    let param = {
                        timestamp: new Date().getTime(),
                        songName: file.name,
                        avatar: require('../assets/mp3-icon.png'),
                        mediaLink: data.mediaUrl,
                        countryCode: "VN",
                    }
                    const headers = {
                        userId: userId,
                        token: token,
                        "Accept-language": playList.language,
                    }
                    makeRequest("post", urlList().URL_PERSONAL.POST_FILE, param, headers, {
                    })
                        .then(({ data }) => {
                            if (data.data) {
                                alert.success(t('add-to-my-playlist-success'));
                            }
                        })
                        .catch((err) => {
                            alert.error(t('unexpected-error') + ": " + err);

                        });
                }
            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);
            });





    };

    return (
        <React.Fragment>

            {/* <label htmlFor="up-button" style={{ display: 'unset' }}>
                <img style={{ float: 'right', height: '80%', width: 'auto', marginBottom: 'auto', marginTop: 'auto', marginLeft: '10px', marginRight: '10px' }} src={require('../assets/upload.png')}
                /></label> */}
            <input id="up-button" type="file" accept="audio/mp3,audio/*;capture=microphone" multiple="" style={{ display: 'none' }} onChange={(e) => { onChange(e) }} />




        </React.Fragment >
    );

}


function Notify({ history, location, firebase }) {

    const [menuNotify, setMenuNotify] = useState(null);
    const playList = useContext(PlayListContext);
    const [notifyData, setNotifyData] = useState(null)
    const { t } = useTranslation();
    const alert = useAlert()
    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const openNotify = Boolean(menuNotify);
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        await showData();

        setLoading(false)
    }, [])

    const showData = async () => {
        const param = {
            timestamp: new Date().getTime(),
            page: 0,
            size: 10,

        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("get", urlList().URL_NOTIFY.LIST, param, headers, {
        })
            .then(({ data }) => {
                if (data.data) {
                    setNotifyData(data.data);
                }
            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);
            });


    }
    const toggleNotify = async (event) => {
        setMenuNotify(event.currentTarget);
    };
    const closeNotify = async () => {
        setMenuNotify(null);

    };
    return (
        <React.Fragment>
            <img style={{ float: 'right', height: '80%', width: 'auto', marginBottom: 'auto', marginTop: 'auto', marginLeft: '10px', marginRight: '10px' }} src={require('../assets/Notification.png')}
                onClick={(e) => { toggleNotify(e) }} />
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                id="basic-menu"
                className="header-notify"
                anchorEl={menuNotify}
                open={openNotify}
                onClose={closeNotify}
            >
                {loading ? <Loader type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100} /> : notifyData.length > 0 ? notifyData.map((row, index) => (

                        <div className="notify-main">

                            <div className="notify-avatar">
                                <img src={row.avatar} className="notify-avatar-img" />


                            </div>
                            <div className="notify-content">
                                <div className="notify-content-title">
                                    {row.title ? row.title : 'Không có thông báo'}


                                </div>
                                <div className="notify-content-descrip">

                                    {row.description}
                                </div>

                            </div>


                        </div>

                    ))

                    :
                    <div className="notify-main">

                        {t('no-notify')}

                    </div>

                }

            </Menu>
        </React.Fragment >
    );
}



function UserMenu(props) {
    const { history, location, firebase } = props;

    const [anchorMenu, setAnchorMenu] = useState(null);
    const { t } = useTranslation();
    const playList = useContext(PlayListContext);


    const users = localStorage.getItem('name') ? localStorage.getItem('name') : '1'
    const phone = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
    const open = Boolean(anchorMenu);
    const toggleMenu = event => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const onSignout = () => {
        localStorage.setItem('userId', '');
        localStorage.setItem('phoneNumber', '');
        localStorage.setItem('email', '');
        localStorage.setItem('token', '');
        localStorage.setItem('userName', '');
        localStorage.setItem('avatar', '');
        localStorage.setItem('tokenExpired', '');
        localStorage.setItem('name', '');
        window.location.href = '/'
        window.location.reload();
    }

    const onGotoInfor = () => {
        // this.props.currentprops.history.push('/infor')
        window.open('/infor')
    }

    const onGotoVip = () => {
        // this.props.currentprops.history.push('/infor')
        window.location.href = 'http://api.umusic.la/umusic-service/v1/pack_mps/res?msisdn=' + phone + '&lang=' + playList.language;
    }


    return (
        <React.Fragment>
            <Button variant="contained"
                className="btn-header"

                style={{ float: 'right', borderRadius: '50rem', marginLeft: '10px', backgroundColor: '#282828', color: 'white' }}
                onClick={(e) => { toggleMenu(e) }}
                endIcon={<KeyboardArrowDownIcon />}

            >{users != "User" ? users : phone}

            </Button>

            <Menu
                id="menu-appbar"
                className="header"
                anchorEl={anchorMenu}
                open={open}
                onClose={() => { closeMenu() }}
            >

                <MenuItem
                    onClick={() => {
                        onGotoInfor();
                    }}
                >
                    {t('infor')}
                </MenuItem>
                <MenuItem
                // onClick={() => {

                //     window.open('https://mp3.zing.vn/tnc')
                // }

                // }
                >
                    {t('policy')}


                </MenuItem>
                <MenuItem
                    onClick={() => onGotoVip()}

                >
                    {t('upgrade-to-vip')}


                </MenuItem>
                <MenuItem
                    onClick={() => onSignout()}
                >

                    {t('logout')}

                </MenuItem>
            </Menu>
        </React.Fragment>
    );

}

function Header(props) {
    const { history, location, firebase } = props;

    const [search, setSearch] = useState('');
    const [top, setTop] = useState(null);
    const [listRelate, setListRelate] = useState([]);
    const { t } = useTranslation();
    const alert = useAlert();
    const playList = useContext(PlayListContext);

    const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
    const phone = localStorage.getItem('phoneNumber') ? localStorage.getItem('phoneNumber') : '1'
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
    const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'

    useEffect(async () => {
        await showData();
        if (history.location.pathname == '/search') {
            setSearch(queryString.parse(location.search).search)
        }
    }, [])




    const onclickSearch = (search) => {
        setListRelate([])
        history.push({
            pathname: '/search',
            search: '?search=' + search
        });
    }

    const _searchAllRelateKeyword = async (search) => {
        if (!search.length) {
            setListRelate([]);
            return;
        };

        const param = {
            timestamp: new Date().getTime(),
            qrTxt: search
        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("get", urlList().URL_SEARCH.RELATE, param, headers, {
        })
            .then(({ data }) => {
                if (data) {
                    setListRelate(data.data)
                }

            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);
            });
    }


    const showData = async () => {
        const param = {
            timestamp: new Date().getTime(),
        }
        const headers = {
            userId: userId,
            token: token,
            "Accept-language": playList.language,
        }
        await makeRequest("get", urlList().URL_SEARCH.TOP, param, headers, {
        })
            .then(({ data }) => {
                if (data) {
                    setTop(data.topKeyword)
                }

            })
            .catch((err) => {
                alert.error(t('unexpected-error') + ": " + err);
            });
    }

    const toSignUp = () => {
        window.location.href = '/sign-up'
    }
    const toSignIn = () => {
        // window.location.href = '/sign-in'

        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + window.location.origin + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://umusic.ringme.vn/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://api.umusic.la/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
        window.location.href = 'https://sso.laoid.net/login?client_id=d64d2475-01eb-4330-b8d1-ec27d87b1ba5&redirect_uri=http://api.umusic.la&use_callback_uri=true'

    }


    const _renderListRelateKeyword = () => {
        if (!listRelate.length) return ;
        return (
            <div className="relate-keyword-content">
                { listRelate.map(i => {
                    return (<div key={i.name} onClick={() => onclickSearch(i.name)} class="relate-keyword-item">
                        <span className="relate-keyword-item__icon"><Search style={{ color: 'white' }} /></span>
                        <span className="relate-keyword-item__text">{i.name}</span>
                    </div>)
                }) }
            </div>
        )
    }

    return (
        <div className="header-main">
            <AppBar position="sticky" elevation={0} >

                <div style={{ width: '50%' }}>
                    <img className="arrow" src={require('../assets/back-arrow.png')} onClick={() => {
                        history.goBack()
                    }} />
                    <img className="arrow" src={require('../assets/forward-arrow.png')} onClick={() => {
                        history.goForward()
                    }} />
                    { !window.location.pathname.includes('ring-phone') && (<CssTextField
                        placeholder={t('searching')}
                        id="filled-multiline-static"
                        variant="outlined"
                        value={search}
                        onChange={(e) => {setSearch(e.target.value); _searchAllRelateKeyword(e.target.value)}}
                        onClick={() => { showData() }}
                        style={{
                            backgroundColor: '#484848',
                            color: 'white',
                            borderRadius: '50rem',
                            height: '100%',
                            width: '50%',
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                onclickSearch(e.target.value);
                            }
                        }}
                        autoComplete='off'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: 'white' }} />
                                </InputAdornment>
                            ),

                            endAdornment: (

                                <InputAdornment position="end" >
                                    {
                                        (search != '') ?
                                            <div style={{ color: 'white', cursor: 'pointer' }} onClick={() => { setSearch(''); setListRelate([]) }}>
                                                X
                                            </div>
                                            : ''
                                    }
                                </InputAdornment>

                            ),
                        }}
                    />)}

                    {(listRelate?.length > 0) && _renderListRelateKeyword()}

                </div>


                <div style={{ width: '50%' }}>
                    {token != '1' ?
                        <>
                            <UserMenu currentprops={props} />
                            <Setting />
                            <Notify />
                            <LanguageMenu />
                        </>

                        :

                        <>
                            <Button
                                variant="contained"
                                className="btn-header"

                                style={{ float: 'right', borderRadius: '50rem', backgroundColor: 'white', color: 'black', marginRight: '10px' }}
                                onClick={() => toSignIn()}
                            >
                                {t('login')}
                            </Button>

                            {/* <Button
                                style={{ float: 'right', borderRadius: '50rem', color: 'white', marginRight: '10px' }}
                                className="btn-header"

                                onClick={() => toSignUp()}
                            >
                                {t('register')}
                            </Button> */}

                            <LanguageMenu />
                        </>
                    }
                </div>

            </AppBar >
        </div >

    )
}


const SignUp = compose(
    withRouter
)(Header);

export default SignUp;
