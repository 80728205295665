import React, { createRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import dashboardRoutes from "./index";

import DashboardLayout from "../layouts/Dashboard";
import DashboardMobile from "../layouts/DashboardMobile";
import { AuthUserContext, PlayListContext } from "../Session";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
import PlayingList from "../components/PlayingList";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
  (
    <Route
      key={index}
      path={path}
      exact
      render={props => (
        <Layout >
          <Component  {...props} />
        </Layout>
      )}
    />
  )
  );

function Routes() {


  const [playList, setPlayList] = useState(null);
  const [playListShuffle, setPlayListShuffle] = useState(null);
  const [isPlaying, setIsPlaying] = useState('');
  const [idPlaylist, setIdPlaylist] = useState(0);
  const [relateSong, setRelateSong] = useState(null);
  const [idSong, setIdSong] = useState(null);
  const [onPlay, setOnPlay] = useState(false);
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
  const [loadLike, setLoadLike] = useState(false);
  const [likeStatus, setLikeStatus] = useState('');
  const [typeLike, setTypeLike] = useState('');
  const [songIdLike, setSongIdLike] = useState(0);
  const [loadSideBar, setLoadSideBar] = useState(false);
  const [loadPlaylist, setLoadPlaylist] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language") || 'lo');

  
  return (
    <PlayListContext.Provider
      value={{
        playList: playList,
        setPlayList: setPlayList.bind(this),
        playListShuffle: playListShuffle,
        setPlayListShuffle: setPlayListShuffle.bind(this),
        isPlaying: isPlaying,
        setIsPlaying: setIsPlaying.bind(this),
        idPlaylist: idPlaylist,
        setIdPlaylist: setIdPlaylist.bind(this),
        idSong: idSong,
        setIdSong: setIdSong.bind(this),
        onPlay: onPlay,
        setOnPlay: setOnPlay.bind(this),
        currentMusicIndex: currentMusicIndex,
        setCurrentMusicIndex: setCurrentMusicIndex.bind(this),
        loadLike: loadLike,
        setLoadLike: setLoadLike.bind(this),
        likeStatus: likeStatus,
        setLikeStatus: setLikeStatus.bind(this),
        loadSideBar: loadSideBar,
        setLoadSideBar: setLoadSideBar.bind(this),
        loadPlaylist: loadPlaylist,
        setLoadPlaylist: setLoadPlaylist.bind(this),
        language: language,
        setLanguage: setLanguage.bind(this),
        songIdLike: songIdLike,
        setSongIdLike: setSongIdLike.bind(this),
        typeLike: typeLike,
        setTypeLike: setTypeLike.bind(this),
        relateSong: relateSong,
        setRelateSong: setRelateSong.bind(this),
        audioRef: createRef({})
      }}
    >
      {isBrowser ?
        <BrowserView>
          <Router>
            <div style={{ display: 'flex', flexDirection: 'row' }}>


              <Switch >
                <Route
                  render={() => (
                    childRoutes(DashboardLayout, dashboardRoutes)
                  )}
                />

              </Switch>

              {window.location.href.includes('sign-in') || window.location.href.includes('sign-up')
                || window.location.href.includes('reset-password') || window.location.href.includes('infor')
                ? ''
                :
                <PlayingList />

              }
            </div>
            {window.location.href.includes('sign-in') || window.location.href.includes('sign-up')
              || window.location.href.includes('reset-password') || window.location.href.includes('infor')

              ? ''
              :


              <Footer />



            }


          </Router>
        </BrowserView>
        :
        <MobileView>
          {/* <img style={{ width: '100%', height: 'auto', marginTop: '100px' }} src={require('../assets/maintainPChow.png')} onClick={() => {
            window.open('fb://Hug.Mlem');
          }} /> */}
          <Router>
            <div style={{ display: 'flex', flexDirection: 'row' }}>


              <Switch >
                <Route
                  render={() => (
                    childRoutes(DashboardMobile, dashboardRoutes)
                  )}
                />

              </Switch>
            </div>
            {window.location.href.includes('sign-in') || window.location.href.includes('sign-up')
              || window.location.href.includes('reset-password') || window.location.href.includes('infor')

              ? ''
              :


              <FooterMobile />



            }


          </Router>
        </MobileView>
      }
    </PlayListContext.Provider>

  )
};

export default Routes;
