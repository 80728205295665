import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from 'axios'
import fileDownload from 'js-file-download'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { PlayListContext } from "../Session";
import { spacing } from "@material-ui/system";
import { useAlert } from 'react-alert'

import { DialogContent, DialogTitle, Dialog, Input, Menu, MenuItem, Modal, TextField } from '@mui/material';
import {
  InputAdornment,
  Divider as MuiDivider,
  Button as MuiButton,

} from "@material-ui/core";
import JSAlert from 'js-alert';
import Dropdown from 'react-multilevel-dropdown';
import PerfectScrollbar from "react-perfect-scrollbar";

import { NavLink as RouterNavLink } from 'react-router-dom';
import 'react-h5-audio-player/lib/styles.css';
import './styleFooter.css';
import makeRequest from "../pages/api/config-request";
import { urlList } from "../pages/api/config.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import LoadingBar from 'react-top-loading-bar'

import { Box } from "@mui/system";
import { FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { useTranslation } from "react-i18next";
const Wrapper = styled.div`

  background: #181818;
  position: fixed;
  left : 0px;
  top: 90vh;
  height: 100%;
  z-Index : 2000001;
  width: 100%;

`;
const Wrapperr = styled.div`

  display: none;
  background: #181818;
  position: fixed;
  left : 0px;
  top: 90vh;
  height: 0;
  z-Index : 1200;
  width: 100%;


`;

const style = {
  position: 'absolute',
  paddingLeft: '2%',
  paddingRight: '2%',
  paddingTop: '2%',
  paddingBottom: '2%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '75%',
  backgroundColor: '#282828',
  boxShadow: 24,
  // textAlign: "center",
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '3%',
};
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CssTextField = styled(TextField)({

  '& .MuiOutlinedInput-input': {

    color: "white",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#484848',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '1rem',
    height: '100%',
    backgroundColor: '#484848',
    '&.Mui-focused fieldset': {
      borderColor: '#484848',
      border: 'unset'
    },
  },

});
const Scrollbar = styled(PerfectScrollbar)`
  background-color: #282828;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const ScrollbarLyric = styled(PerfectScrollbar)`
  margin-left : 5%; 
  width : 40%;
  height : 50% !important;
  margin-top: auto;
  margin-bottom : auto;
  background-color:rgba(255, 0, 0, 0);
  border-right: 0px solid rgba(0, 0, 0, 0);
`;



function Footer() {
  const playList = useContext(PlayListContext);

  useEffect(() => {
    document.getElementsByTagName('audio')[0].setAttribute('id', 'audioPlayer')
    
  }, []);

 

  const alert = useAlert()
  const { t } = useTranslation();





  const [listCmtFooter, setListCmtFooter] = useState(null)
  const [openCmtFooter, setOpenCmtFooter] = useState(false);

  const [loading, setLoading] = useState(true)
  const [showLyric, setShowLyric] = useState(false)
  const [songLyric, setSongLyric] = useState(null)
  const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
  const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
  const [cmt, setCmt] = useState('')
  const [userPlaylist, setUserPlaylist] = useState(null)
  const [repeatAll, setRepeatAll] = useState(false)
  const [repeatOne, setRepeatOne] = useState(false)
  const [shuffleOn, setShuffleOn] = useState(false)
  const [loadSameSrc, setLoadSameSrc] = useState(false)
  const [countCmt, setCountCmt] = useState(1);

  const handleClickPrevious = async () => {
    setLoadSameSrc(true);
    if (playList.currentMusicIndex == 0) {
      // if(playList.playList[0].mediaPath == playList.playList[playList.playList.length - 1].mediaPath){
      // }
      playList.setCurrentMusicIndex(playList.playList.length - 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)


    }

    else {
      // if(playList.playList[playList.currentMusicIndex - 1].mediaPath == playList.playList[playList.currentMusicIndex].mediaPath){

      // }
      playList.setCurrentMusicIndex(playList.currentMusicIndex - 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)


    }
    setLoadSameSrc(false);
  }

  useEffect(() => {
    logPlay();
  }, [playList.playList]);


  useEffect(() => {
    showLyrics();
    logPlay();

  }, [playList.currentMusicIndex]);

  const handleClickNext = async () => {
    setLoadSameSrc(true);

    if (playList.currentMusicIndex == playList.playList.length - 1) {
      playList.setCurrentMusicIndex(0)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
    }

    else {
      playList.setCurrentMusicIndex(playList.currentMusicIndex + 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)

    }

    setLoadSameSrc(false);

  }
  const handleEnded = async () => {
    if (playList.currentMusicIndex == playList.playList.length - 1) {
      if (repeatAll == true) {
        playList.setCurrentMusicIndex(0)
        playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
      }

    }
    else {
      playList.setCurrentMusicIndex(playList.currentMusicIndex + 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)

    }
  }





  const likeSong = async (songId, songAvatar, songName) => {
    if (token === '1') {
      alert.error(t('need-login'))
    }
    else {
      playList.setLoadLike(true)
      playList.setLikeStatus('like')
      playList.setTypeLike('footer')


      let newArr = [...playList.playList];
      newArr[playList.currentMusicIndex].isLike = 1;
      playList.setPlayList(newArr)

      const param = {
        timestamp: new Date().getTime(),
        userName: users,
        action: "LIKE",
        songId: songId,
        songName: songName,
        songAvatar: songAvatar
      }

      const headers = {
        userId: userId,
        token: token,
        "Accept-language": playList.language,
      }
      await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
      })
        .then(({ data }) => {
          if (data.data) {
            playList.setLoadLike(true)

            playList.setLoadLike(false)
          }

        })
        .catch((err) => {
          alert.error(t('unexpected-error') + ": " + err)
        });
    }
  }
  const unLikeSong = async (songId, songAvatar, songName) => {
    playList.setLoadLike(true)
    playList.setLikeStatus('unlike')
    playList.setTypeLike('footer')
    let newArr = [...playList.playList];
    newArr[playList.currentMusicIndex].isLike = 0;
    playList.setPlayList(newArr)
    const param = {
      timestamp: new Date().getTime(),
      userName: users,
      action: "UNLIKE",
      songId: songId,
      songName: songName,
      songAvatar: songAvatar
    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {

          playList.setLoadLike(true)

          playList.setLoadLike(false)

        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });

  }



  const handleOpenCmtFooter = async () => {
    setCountCmt(1);
    await showCmtFooter();
    setOpenCmtFooter(true)
  };
  const handleCloseCmtFooter = () => {
    setCountCmt(1);
    setOpenCmtFooter(false)
  };
  const showCmtFooter = async () => {
    setCountCmt(1);
    const param = {
      timestamp: new Date().getTime(),
      contentId: playList.idSong,
      level: 1,
      page: 0,
      size: 10,
    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("get", urlList().URL_SONG.CMT_LIST, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          setListCmtFooter(data.data);
          setLoading(false)
        }
      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }

  const date = (dateSince) => {
    let t1 = new Date().getTime();


    let result1 = t1 - dateSince;
    let result = result1 / 1000;

    if (result > 60 && result < 3600) {
      return parseInt(result / 60) + ' ' + t('minutes-ago');
    }
    else if (result > 3600 && result < 86400) {
      return parseInt(result / 3600) + ' ' + t('hours-ago');
    }
    else if (result > 86400 && result < 604800) {
      return parseInt(result / 86400) + ' ' + t('days-ago');
    }
    else if (result > 604800 && result < 2419200) {
      return parseInt(result / 604800) + ' ' + t('weeks-ago');
    }
    else if (result > 2419200 && result < 29030400) {
      return parseInt(result / 2419200) + ' ' + t('months-ago');
    }
    else if (result > 29030400) {
      return parseInt(result / 29030400) + ' ' + t('years-ago');
    }
    else {
      return t('just-now');
    }


  }

  const postCmtFooter = async () => {
    const param = {
      timestamp: new Date().getTime(),
      toContentId: playList.idSong,
      withContent: cmt,
      userName: users,
      level: 1,
      songId: playList.idSong,
      songName: playList.playList[playList.currentMusicIndex].songName,
      songAvatar: playList.playList[playList.currentMusicIndex].avatar,

    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.POST_LIST, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          showCmtFooter();
          setCmt('')
        }
      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }
  const clearCmtFooter = async () => {
    await setCmt('')
  }
  const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
  ));

  const toSignIn = () => {
    // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + window.location.origin + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
    // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://umusic.ringme.vn/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
    // window.location.href = 'https://uid.com.la/auth/signin?redirectUri=' + 'http://api.umusic.la/' + '&state=umusic&clientId=3cf85bc2-fd4a-48fc-b85b-c2fd4a38fc04&isFromWeb=true'
    window.location.href = 'https://sso.laoid.net/login?client_id=d64d2475-01eb-4330-b8d1-ec27d87b1ba5&redirect_uri=http://api.umusic.la&use_callback_uri=true'

  }
  const handleDownload = (url, filename) => {

    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }


  const likeCmt = async (cmtId) => {
    const param = {
      timestamp: new Date().getTime(),
      userName: users,
      commentId: cmtId,
      action: "LIKE",
      songId: playList.idSong,
      songName: playList.playList[playList.currentMusicIndex].songName,
      songAvatar: playList.playList[playList.currentMusicIndex].avatar,

    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.LIKE_CMT, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          showCmtFooter()
        }

      })

  }
  const unLikeCmt = async (cmtId) => {

    const param = {
      timestamp: new Date().getTime(),
      userName: users,
      commentId: cmtId,
      action: "UNLIKE",
      songId: playList.idSong,
      songName: playList.playList[playList.currentMusicIndex].songName,
      songAvatar: playList.playList[playList.currentMusicIndex].avatar,
    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.LIKE_CMT, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          showCmtFooter()
        }

      })


  }
  const showLyrics = async () => {
    if (playList.playList) {
      let param = {
        timestamp: new Date().getTime(),
        songId: playList.playList[playList.currentMusicIndex].id,
      }
      const headers = {
        userId: userId,
        token: token,
        "Accept-language": playList.language,

      }
      await makeRequest("get", urlList().URL_SONG.LYRICS, param, headers, {
      })
        .then(({ data }) => {

          if (data) {
            setSongLyric(data.songInfo)
          }

        })
        .catch((err) => {
          alert.error(t('unexpected-error') + ": " + err)

        });
    }


  }

  const copyAlbum = () => {

    navigator.clipboard.writeText(playList.playList[playList.currentMusicIndex].link).then(function () {
      alert.success(t('copy-link') + "!");
    }, 
    
    function () {
      alert.error(t('unexpected-error'));
    });
    
  }


  const addUserPlaylist = async (rowPlaylist) => {
    const param = {
      timestamp: new Date().getTime(),
      playlistId: rowPlaylist.id,
      songId: playList.playList[playList.currentMusicIndex].id

    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_PERSONAL.ADD_SONG, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          alert.success(t('add-to-my-playlist-success'));


        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }

  const showUserPlaylist = async () => {
    const param = {
      timestamp: new Date().getTime(),
      page: 0,
      size: 10,

    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("get", urlList().URL_PERSONAL.OWNER_PLAYLIST, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          setUserPlaylist(data.data)


        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });


  }

  const onClickRepeatToOne = async () => {
    document.getElementById('audioPlayer').loop = true;
    setRepeatAll(false);
    setRepeatOne(true);

  }

  const onClickRepeatToAll = async () => {
    document.getElementById('audioPlayer').loop = false;
    setRepeatAll(true);
    setRepeatOne(false);

  }
  const onClickRepeatToNone = async () => {
    document.getElementById('audioPlayer').loop = false;
    setRepeatAll(false);
    setRepeatOne(false);

  }
  const onClickShuffleOn = async () => {

    if (playList.playList && playList.playList.length > 2) {

      let array1 = [];
      array1.push(playList.playList[playList.currentMusicIndex]);
      let array2 = playList.playList;
      array2.splice(playList.currentMusicIndex, 1);
      shuffle(array2);
      for (let index = 0; index < array2.length; index++) {
        array1.push(array2[index]);
      }
      playList.setPlayList(array1);
      playList.setCurrentMusicIndex(0);


      // shifted = myarray.shift();
    }
    setShuffleOn(true);

  }
  const onClickShuffleOff = async () => {


    // playList.setPlayList(playList.playListShuffle)
    // playList.setCurrentMusicIndex(0);


    setShuffleOn(false);

  }
  const deleteCmt = async (cmtId) => {

    const param = {
      timestamp: new Date().getTime(),
      commentId: cmtId,
      songId: playList.idSong
    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.DELETE, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {
          showCmtFooter()
        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err);

      });

  }
  const LoadMoreCmt = async () => {

    const param = {
      timestamp: new Date().getTime(),
      contentId: playList.idSong,
      level: 1,
      page: countCmt,
      size: 10,
    }
    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    const result = await makeRequest("get", urlList().URL_SONG.CMT_LIST, param, headers, {})



    const dataArr = []
    for (const [key, value] of Object.entries(listCmtFooter)) {
      dataArr.push(value)
    }
    if (result.data && result.data.data) {
      for (const [key, value] of Object.entries(result.data.data)) {
        dataArr.push(value)
      }
    }
    setCountCmt(count => count + 1);
    setListCmtFooter(dataArr)


  }
  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  }

  const logPlay = async () => {
    if (playList.playList) {
      const param = {
        timestamp: new Date().getTime(),
        mediaLink: playList.playList[playList.currentMusicIndex].mediaPath,
        songId: playList.playList[playList.currentMusicIndex].id,
        // songId:2366273,
        // mediaLink:"http://freecdn.umusic.la/cms_upload/song/audio/2022/12/16/73f1772f46a653271a66f0d42e81fd44.mp3",
       
      }
      // console.log("444444", param)
      const headers = {
        userId: userId,
        token: token,
        "Accept-language": playList.language,
      }
      await makeRequest("post", urlList().URL_LOG.LISTEN, param, headers, {
      })
    }
    
  }



  return (
    <>
      {
        playList.playList ?
          <>

            <Wrapper className={showLyric ? "show-lyrics" : ""} style={{backgroundColor:"#170F23"}}>

              <div style={{ display: 'flex', flexDirection: 'row', height: '10vh' }}>
                <div className={showLyric ? "hide-lyrics" : ""}></div>
                <div className={showLyric ? "change-lyrics" : ""}
                  style={{ display: 'flex', flexDirection: 'row', height: '10vh', width: '25%' }}>
                  <div
                    style={{ width: '15%', height: 'auto', marginLeft: '3%', marginTop: 'auto', marginBottom: 'auto' }}
                  >

                    <img src={playList.playList[playList.currentMusicIndex].avatar ? playList.playList[playList.currentMusicIndex].avatar : require('../assets/mp3-icon.png')}

                      style={{ width: '100%', height: 'auto' }}
                    />

                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', width: '40%', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2%', marginRight: '0.5%' }}>
                    <div style={{ fontSize: '0.8vw', textOverflow: 'ellipsis', color: 'white', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {playList.playList[playList.currentMusicIndex].songName}
                    </div>
                    <div style={{ fontSize: '0.6vw', textOverflow: 'ellipsis', color: 'rgba(255, 255, 255, 0.5)' }}>
                      {playList.playList[playList.currentMusicIndex].artists ? playList.playList[playList.currentMusicIndex].artists[0].aliasName : ''}
                    </div>
                  </div>
                  <div style={{ width: '45%', marginTop: 'auto', marginBottom: 'auto', height: 'auto' }} >

                    {playList.playList[playList.currentMusicIndex].isLike == 1 ?
                      <img src={require('../assets/like-solid.png')} style={{ width: '3vh' }}
                        onClick={() => unLikeSong(playList.playList[playList.currentMusicIndex].id, playList.playList[playList.currentMusicIndex].avatar, playList.playList[playList.currentMusicIndex].songName)} />
                      : <img src={require('../assets/like.png')} style={{ width: '3vh' }}
                        onClick={() => likeSong(playList.playList[playList.currentMusicIndex].id, playList.playList[playList.currentMusicIndex].avatar, playList.playList[playList.currentMusicIndex].songName)} />}


                    <Dropdown

                      position="top-left"
                      buttonClassName="drop-down-custom-btn"
                      wrapperClassName="drop-down-custom-wrap"
                      menuClassName="drop-down-custom-menu-footer"
                      title={<img src={require('../assets/more.png')} style={{ width: '3vh' }} />}
                    >
                      <Dropdown.Item
                        className="drop-down-custom-items"
                        onClick={() => { handleOpenCmtFooter() }}

                      >
                        {t('comment')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="drop-down-custom-items"
                        onClick={() => {
                          showUserPlaylist()
                        }}
                        onMouseEnter={() => showUserPlaylist()}

                      >

                        {t('add-to-my-playlist')}


                        <Dropdown.Submenu position="right" className="drop-down-custom-menu-sub-footer-playlist" >

                          <Scrollbar>
                            {userPlaylist ? userPlaylist.map((rowUP, indexUP) => (

                              <Dropdown.Item className="drop-down-custom-items"
                                onClick={() => {
                                  addUserPlaylist(rowUP)
                                }}>
                                {rowUP.playlistName}
                              </Dropdown.Item>

                            )) : ''}
                          </Scrollbar>
                        </Dropdown.Submenu>

                      </Dropdown.Item>



                      <Dropdown.Item

                        className="drop-down-custom-items"

                      >
                        {t('share')}

                        <Dropdown.Submenu position="right" className="drop-down-custom-menu-sub-footer" >
                          <Dropdown.Item className="drop-down-custom-items"
                          >
                            <FacebookShareButton
                              url={playList.playList[playList.currentMusicIndex].link}
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={15} round /> Facebook
                            </FacebookShareButton>
                          </Dropdown.Item>


                          <Dropdown.Item className="drop-down-custom-items">
                            <WhatsappShareButton
                              url={playList.playList[playList.currentMusicIndex].link}
                              className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={15} round /> WhatsApp
                            </WhatsappShareButton>
                          </Dropdown.Item>
                        </Dropdown.Submenu>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => { copyAlbum() }}
                        className="drop-down-custom-items"
                      >
                        {t('copy-link')}
                      </Dropdown.Item>

                      <Dropdown.Item
                        className="drop-down-custom-items"
                        onClick={() => {
                          if (token != 1) {
                            handleDownload(playList.playList[playList.currentMusicIndex].mediaPath, playList.playList[playList.currentMusicIndex].songName.trim() + '.mp3')
                          }
                          else {
                            alert.error(t('need-login'));
                          }

                        }}

                      >
                        {t('download')}
                      </Dropdown.Item>


                    </Dropdown>
                  </div>
                </div>

                <LoadingBar></LoadingBar>
                <div

                  style={{
                    width: '40%', marginLeft: 'auto', marginRight: 'auto', height: 'auto', marginTop: 'auto', marginBottom: 'auto'
                  }}>




                  <AudioPlayer
                    
                    className={showLyric ? "show-lyrics" : ""}
                    style={{ backgroundColor: 'transparent' }}
                    autoPlay={true}
                    listenInterval={loadSameSrc ? 0 : null}
                    layout="stacked-reverse"
                    onPlay={(e) => {
                      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
                      // playList.setIdSong(2366158);
                      playList.setOnPlay(true)
                    }}
                    onPause={(e) => {
                      playList.setOnPlay(false)
                    }}
                    isPlaying={playList.onPlay}
                    showSkipControls={true}
                    showJumpControls={false}
                    // src={'http://freecdn.umusic.la/cms_upload/song/audio/2022/12/16/73f1772f46a653271a66f0d42e81fd44.mp3'}
                    src={playList.playList[playList.currentMusicIndex].mediaPath}
                    onClickPrevious={handleClickPrevious}
                    onClickNext={handleClickNext}
                    onEnded={handleEnded}
                    ref={playList.audioRef}
                    autoPlayAfterSrcChange={true}
                    customControlsSection={
                      [

                        (repeatAll == true && repeatOne == false) ?
                          <img src={require('../assets/repeat-all.png')} className="footer-repeat-btn" onClick={async () => { onClickRepeatToOne() }} />
                          : (repeatAll == false && repeatOne == true) ? <img src={require('../assets/repeat-one.png')} className="footer-repeat-btn" onClick={async () => { onClickRepeatToNone() }} />
                            : <img src={require('../assets/repeat-none.png')} className="footer-repeat-btn" onClick={async () => {
                              onClickRepeatToAll()
                            }} />
                        ,
                        RHAP_UI.MAIN_CONTROLS,
                        (shuffleOn == true) ?


                          <img src={require('../assets/shuffle-on.png')} className="footer-shuffle-btn" onClick={async () => {
                            onClickShuffleOff()
                          }} />
                          :
                          <img src={require('../assets/shuffle.png')} className="footer-shuffle-btn" onClick={async () => {
                            onClickShuffleOn()
                          }} />
                        ,
                        RHAP_UI.VOLUME_CONTROLS,
                      ]}
                      

                  />
                </div>


                <div className={showLyric ? "hide-lyrics" : ""}></div>
                <div className={showLyric ? "change-lyrics" : "footer-karaoke"}>
                  <img src={require('../assets/lyrics.png')} className="footer-karaoke-btn" style={{ width: '3vh', marginLeft: '10px' }} onClick={async () => {
                    await showLyrics();
                    setShowLyric(!showLyric);
                  }} />
                </div>

                <Modal
                  open={openCmtFooter}
                  onClose={handleCloseCmtFooter}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableEnforceFocus
                >
                  <Box style={style}>
                    <div style={{ fontSize: '24px', color: 'white', width: '100%', marginBottom: '10px' }}>
                      <div style={{ float: 'left' }}>{t('comment')}</div>
                      <div style={{ float: 'right' }}>    <img src={require('../assets/close.png')} style={{ width: '20px', height: '20px' }} onClick={handleCloseCmtFooter} /> </div>
                    </div>
                    <Divider my={6} />
                    <div style={{ fontSize: '100%', color: 'white', marginTop: '10px' }}>
                      {playList.playList[playList.currentMusicIndex].totalComment ? playList.playList[playList.currentMusicIndex].totalComment : listCmtFooter ? listCmtFooter.length : ''} {t('comment')}

                    </div>

                    <div style={{ width: '100%', height: '12%', display: 'flex', flexDirection: 'row' }} >
                      {token == '1' ?
                        ''
                        :
                        <div style={{ marginTop: '20px', width: '100%', height: '50%', display: 'flex', flexDirection: 'row' }}>
                          <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU'} style={{ width: 'auto', height: '100%', borderRadius: '50rem', marginRight: '2%' }} />

                          <CssTextField
                            id="filled-multiline-static"
                            variant="outlined"
                            autoComplete='off'
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                postCmtFooter()
                              }
                            }}
                            value={cmt}
                            onChange={(event) => { setCmt(event.target.value) }}
                            style={{
                              width: '100%'
                            }}
                          />

                        </div>
                      }
                    </div>
                    {token != '1' ?
                      <div className="btn-post-cmt">


                        <Button
                          type="submit"
                          className="btn-submit-cmt"
                          variant="contained"
                          color="primary"
                          mb={2}
                          onClick={() => {
                            postCmtFooter()
                          }}


                        >
                          <b> {t('post-cmt')}</b>
                        </Button>


                        <Button
                          className="btn-cancel-cmt"
                          type="submit"

                          variant="contained"
                          color="primary"
                          mb={2}
                          onClick={() => {
                            clearCmtFooter()
                          }}

                        >
                          <b> {t('cancel')} </b>
                        </Button>


                      </div>
                      : ''
                    }

                    <Scrollbar>


                      {
                        loading ? <LoadingBar></LoadingBar> : listCmtFooter && listCmtFooter.length > 0 ? listCmtFooter.map((rowChild, indexChild) => (



                          <div style={{ width: '90%', height: 'fit-content', display: 'flex', flexDirection: 'row', marginBottom: '3.2%', backgroundColor: "#282828" }} >



                            <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU'} style={{ width: '5%', height: '100%', borderRadius: '50rem', marginRight: '2%' }} />

                            <div className='cmt-show'>
                              <div className='cmt-name-show display-flex-row'>
                                {rowChild.name}&nbsp; - &nbsp; <div style={{ fontSize: '12px' }}> {date(rowChild.commentAt)}</div>

                              </div>
                              <div className='cmt-content-show'>
                                {rowChild.content}
                              </div>
                              <div className="cmt-show-btn" style={{ height: 'fit-content', marginTop: '10px' }}>
                                <div className="display-flex-row" style={{ height: 'fit-content' }}>
                                  {token != '1' ?
                                    <>

                                      {rowChild.userId == userId ?
                                        <>
                                          {
                                            rowChild.isLike == 1 ? <img src={require('../assets/like-solid.png')} style={{ width: '13%', height: '100%', marginRight: '2%' }} onClick={() => {
                                              unLikeCmt(rowChild.commentId)
                                            }} /> : <img src={require('../assets/like-cmt.png')} style={{ width: '13%', height: '100%', marginRight: '2%' }} onClick={() => {
                                              likeCmt(rowChild.commentId)
                                            }} />
                                          }
                                        </>
                                        :
                                        <>


                                          {
                                            rowChild.isLike == 1 ? <img src={require('../assets/like-solid.png')} style={{ width: '20%', height: '100%', marginRight: '2%' }} onClick={() => {
                                              unLikeCmt(rowChild.commentId)
                                            }} /> : <img src={require('../assets/like-cmt.png')} style={{ width: '20%', height: '100%', marginRight: '2%' }} onClick={() => {
                                              likeCmt(rowChild.commentId)
                                            }} />
                                          }
                                        </>

                                      }

                                    </>
                                    :
                                    <img src={require('../assets/like-cmt.png')} style={{ width: '20%', height: '100%', marginRight: '2%' }}

                                    />
                                  }


                                  <div style={{ marginLeft: '10px' }}>
                                    {rowChild.totalLiked}
                                  </div>
                                  {/* <div style={{ marginLeft: '10px', cursor: 'pointer' }}>
                                      <b> {t('reply-cmt')} </b>
                                    </div> */}
                                  {rowChild.userId == userId ?



                                    <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                      deleteCmt(rowChild.commentId)
                                    }}>
                                      <b> {t('delete')} </b>
                                    </div>

                                    : ''
                                  }
                                </div>


                              </div>
                            </div>





                          </div>


                        ))

                          :
                          <>
                            <div style={{ width: '100%', marginTop: '20px' }}>

                              <img src={require('../assets/comment.png')} style={{ width: '30%', height: '100%', marginLeft: '33%' }} />

                            </div>
                            <div style={{ width: '100%', }}>
                              <div style={{ textAlign: 'center', fontSize: '100%', }}>
                                {t('none-cmt-yet')}
                              </div>
                            </div>
                          </>

                      }
                    </Scrollbar>



                    <div style={{
                      height: '4%',
                      marginTop: '2%', textAlign: 'center', marginBottom: '3%',
                    }}>


                      <Button
                        id="basic-button"
                        variant="contained"
                        style={{ fontSize: '0.9vw', borderRadius: '50rem', marginLeft: 'auto', backgroundColor: '#F3903F', color: 'white', width: 'auto', marginRight: 'auto' }}
                        onClick={(e) => { LoadMoreCmt() }}
                      >{t("more")}
                      </Button>


                    </div>



                    {token == '1' ?
                      <>

                        <Button
                          variant="contained"
                          style={{ borderRadius: '50rem', backgroundColor: 'orange', color: 'black', marginRight: '10px', width: '100%' }}
                          onClick={toSignIn}
                        >
                          {t('need-login')}
                        </Button>
                      </>
                      : ''}
                  </Box>
                </Modal>


              </div>



            </Wrapper >

            <div className={showLyric == true ? 'display-block lyric-footer ' : 'display-none'}>
              <img src={songLyric ? songLyric.avatar : ''} style={{ width: '30%', height: 'auto', marginLeft: '10%' }} />
              <ScrollbarLyric>
                {songLyric ?
                  <>

                    <div className="footer-lyrics">

                      {songLyric.lyric != "" ? songLyric.lyric : t('none-lyrics')}

                    </div>

                    <div className="footer-lyrics-close-btn" onClick={() => {
                      setShowLyric(!showLyric);
                    }} >
                      X
                    </div>
                  </>

                  :
                  <>
                    <div className="footer-lyrics">

                      {t('none-lyrics')}

                    </div>

                    <div className="footer-lyrics-close-btn" onClick={() => {
                      setShowLyric(!showLyric);
                    }} >
                      X
                    </div>

                  </>



                }
              </ScrollbarLyric>
            </div>
          </>
          : <Wrapperr>

            <AudioPlayer


              style={{ backgroundColor: '#181818' }}
              autoPlay

              src={''}


            />




          </Wrapperr >
      }
    </>
  );
}


export default Footer;
