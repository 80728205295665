import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9KsRmj1ZZh1_8h5CeRxDmAOOuqa5iROQ",
  authDomain: "umusic-laos.firebaseapp.com",
  projectId: "umusic-laos",
  storageBucket: "umusic-laos.appspot.com",
  messagingSenderId: "920330817539",
  appId: "1:920330817539:web:962922e4ee7b0419ae3e36",
  measurementId: "G-DX2ETQ8HE0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;