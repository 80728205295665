import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axios from 'axios'
import fileDownload from 'js-file-download'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { PlayListContext } from "../Session";
import { spacing } from "@material-ui/system";
import { useAlert } from 'react-alert'

import { DialogContent, DialogTitle, Dialog, Input, Menu, MenuItem, Modal, TextField } from '@mui/material';
import {
  InputAdornment,
  Divider as MuiDivider,
  Button as MuiButton,

} from "@material-ui/core";
import JSAlert from 'js-alert';
import Dropdown from 'react-multilevel-dropdown';
import PerfectScrollbar from "react-perfect-scrollbar";

import { NavLink as RouterNavLink } from 'react-router-dom';
import 'react-h5-audio-player/lib/styles.css';
import './styleFooter.css';
import makeRequest from "../pages/api/config-request";
import { urlList } from "../pages/api/config.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoadingBar from 'react-top-loading-bar'
import { useTranslation } from "react-i18next";
import RingPhone from "./RingPhone";
const Wrapper = styled.div`

  background: #121212;
  position: fixed;
  height: 10vh;
  bottom: 0px;
  z-Index : 2000001;
  width: 100%;
  border-radius: 1rem;

`;
const Wrapperr = styled.div`

  display: none;
  background: #181818;
  position: fixed;
  left : 0px;
  top: 90vh;
  height: 0;
  z-Index : 1200;
  width: 100%;


`;

const style = {
  position: 'absolute',
  paddingLeft: '2%',
  paddingRight: '2%',
  paddingTop: '2%',
  paddingBottom: '2%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '75%',
  backgroundColor: '#282828',
  boxShadow: 24,
  // textAlign: "center",
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '3%',
};
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const CssTextField = styled(TextField)({

  '& .MuiOutlinedInput-input': {

    color: "white",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#484848',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '1rem',
    height: '100%',
    backgroundColor: '#484848',
    '&.Mui-focused fieldset': {
      borderColor: '#484848',
      border: 'unset'
    },
  },

});
const Scrollbar = styled(PerfectScrollbar)`
  background-color: #282828;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const ScrollbarLyric = styled(PerfectScrollbar)`
  margin-left : 5%; 
  width : 40%;
  height : 50% !important;
  margin-top: auto;
  margin-bottom : auto;
  background-color:rgba(255, 0, 0, 0);
  border-right: 0px solid rgba(0, 0, 0, 0);
`;



function Footer({ history, location, firebase }) {

  useEffect(() => {
    // console.log('111111111111111111',window.location);
    document.getElementsByTagName('audio')[0].setAttribute('id', 'audioPlayer')

  });


  const alert = useAlert()
  const { t } = useTranslation();




  const playList = useContext(PlayListContext);

  const [showLyric, setShowLyric] = useState(false);
  const [showRingPhone, setShowRingPhone] = useState(false);

  const [songLyric, setSongLyric] = useState(null)
  const users = localStorage.getItem('userName') ? localStorage.getItem('userName') : '1'
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : '1'
  const userId = localStorage.getItem('userId') ? localStorage.getItem('userId') : '1'
  const [cmt, setCmt] = useState('')
  const [userPlaylist, setUserPlaylist] = useState(null)
  const [repeatAll, setRepeatAll] = useState(false)
  const [repeatOne, setRepeatOne] = useState(false)
  const [shuffleOn, setShuffleOn] = useState(false)
  const [loadSameSrc, setLoadSameSrc] = useState(false)
  const [countCmt, setCountCmt] = useState(1);

  const handleClickPrevious = async () => {

    if (playList.currentMusicIndex == 0) {
      playList.setCurrentMusicIndex(playList.playList.length - 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
    }

    else {
      playList.setCurrentMusicIndex(playList.currentMusicIndex - 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)

    }

  }
  useEffect(() => {
    setLoadSameSrc(true);
    if (playList.playList) {
      showLyrics()
    }
    setLoadSameSrc(false);

  }, [playList.currentMusicIndex]);
  const handleClickNext = async () => {

    if (playList.currentMusicIndex == playList.playList.length - 1) {
      playList.setCurrentMusicIndex(0)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
    }

    else {
      playList.setCurrentMusicIndex(playList.currentMusicIndex + 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)

    }


  }
  const handleEnded = async () => {
    if (playList.currentMusicIndex == playList.playList.length - 1) {
      if (repeatAll == true) {
        playList.setCurrentMusicIndex(0)
        playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
      }

    }
    else {
      playList.setCurrentMusicIndex(playList.currentMusicIndex + 1)
      playList.setIdSong(playList.playList[playList.currentMusicIndex].id)

    }
  }





  const likeSong = async (songId, songAvatar, songName) => {
    if (token === '1') {
      alert.error(t('need-login'))
    }
    else {
      playList.setLoadLike(true)
      playList.setLikeStatus('like')
      playList.setTypeLike('footer')


      let newArr = [...playList.playList];
      newArr[playList.currentMusicIndex].isLike = 1;
      playList.setPlayList(newArr)

      const param = {
        timestamp: new Date().getTime(),
        userName: users,
        action: "LIKE",
        songId: songId,
        songName: songName,
        songAvatar: songAvatar
      }

      const headers = {
        userId: userId,
        token: token,
        "Accept-language": playList.language,

      }
      await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
      })
        .then(({ data }) => {
          if (data.data) {
            playList.setLoadLike(true)

            playList.setLoadLike(false)
          }

        })

    }
  }
  const unLikeSong = async (songId, songAvatar, songName) => {
    playList.setLoadLike(true)
    playList.setLikeStatus('unlike')
    playList.setTypeLike('footer')
    let newArr = [...playList.playList];
    newArr[playList.currentMusicIndex].isLike = 0;
    playList.setPlayList(newArr)
    const param = {
      timestamp: new Date().getTime(),
      userName: users,
      action: "UNLIKE",
      songId: songId,
      songName: songName,
      songAvatar: songAvatar
    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("post", urlList().URL_SONG.LIKE, param, headers, {
    })
      .then(({ data }) => {
        if (data.data) {

          playList.setLoadLike(true)

          playList.setLoadLike(false)

        }

      })

  }




  const NavLink = React.forwardRef((props, ref) => (
    <RouterNavLink innerRef={ref} {...props} />
  ));




  const showLyrics = async () => {

    const param = {
      timestamp: new Date().getTime(),
      songId: playList.playList ? playList.playList[playList.currentMusicIndex].id : '',
    }

    const headers = {
      userId: userId,
      token: token,
      "Accept-language": playList.language,

    }
    await makeRequest("get", urlList().URL_SONG.LYRICS, param, headers, {
    })
      .then(({ data }) => {

        if (data) {
          setSongLyric(data.songInfo)
        }

      })
      .catch((err) => {
        alert.error(t('unexpected-error') + ": " + err)

      });

  }







  return (
    <>
      {
        playList.playList ?
          <>

            <Wrapper className={showLyric ? "show-lyrics" : ""}>

              <div style={{ display: 'flex', flexDirection: 'row', height: '10vh' }}>
                <div className={showLyric ? "hide-lyrics" : ""}></div>
                <div className={showLyric ? "change-lyrics" : ""}
                  style={{ display: 'flex', flexDirection: 'row', height: '10vh', width: '60%' }}>
                  <div
                    style={{ width: 'auto', height: 'auto', marginLeft: '3%', marginTop: 'auto', marginBottom: 'auto' }}
                  >
                    <img src={playList.playList[playList.currentMusicIndex].avatar ? playList.playList[playList.currentMusicIndex].avatar : require('../assets/mp3-icon.png')}
                      style={{ width: '8vh', height: '8vh' }}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', width: 'inherit', marginTop: 'auto', marginBottom: 'auto', marginLeft: '2%', marginRight: '0.5%' }}>
                    <div style={{ fontSize: '3.8vw', textOverflow: 'ellipsis', color: 'white', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {playList.playList[playList.currentMusicIndex].songName}
                    </div>
                    <div style={{ fontSize: '3.5vw', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: 'rgba(255, 255, 255, 0.5)' }}>
                      {playList.playList[playList.currentMusicIndex].artists ? playList.playList[playList.currentMusicIndex].artists[0].aliasName : ''}
                    </div>
                  </div>
                </div>
                {loadSameSrc ?
                  <LoadingBar></LoadingBar> :
                  <div

                    style={{
                      width: '40%', marginLeft: 'auto', marginRight: '15px', height: 'auto', marginTop: 'auto', marginBottom: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px'
                    }}>




                    <AudioPlayer
                      className={showLyric ? "show-lyrics" : ""}
                      style={{ backgroundColor: '#121212' }}
                      autoPlay
                      onPlay={(e) => {
                        playList.setIdSong(playList.playList[playList.currentMusicIndex].id)
                        playList.setOnPlay(true)
                      }}
                      onPause={(e) => {
                        playList.setOnPlay(false)

                      }}
                      layout="horizontal"
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                      customProgressBarSection={[]}
                      isPlaying={playList.onPlay}
                      showSkipControls={false}
                      showJumpControls={false}
                      src={playList.playList[playList.currentMusicIndex].mediaPath}
                      onClickPrevious={handleClickPrevious}
                      onClickNext={handleClickNext}
                      onEnded={handleEnded}
                      autoPlayAfterSrcChange={true}
                      ref={playList.audioRef}


                    />
                    {
                      playList.playList[playList.currentMusicIndex].isLike == 1 ?
                        <img src={require('../assets/like-solid.png')} style={{ width: '3vh' }}
                          onClick={() => unLikeSong(playList.playList[playList.currentMusicIndex].id, playList.playList[playList.currentMusicIndex].avatar, playList.playList[playList.currentMusicIndex].songName)} />
                        : <img src={require('../assets/like.png')} style={{ width: '3vh' }}
                          onClick={() => likeSong(playList.playList[playList.currentMusicIndex].id, playList.playList[playList.currentMusicIndex].avatar, playList.playList[playList.currentMusicIndex].songName)} />
                    }
                    {/* <div className={showLyric ? "hide-lyrics" : ""}></div> */}
                    <div className={showLyric ? "change-lyrics" : "footer-karaoke-mobile"}>


                      <img src={require('../assets/lyrics.png')} className="footer-karaoke-btn" style={{ width: '3vh' }} onClick={async () => {
                        await showLyrics();
                        setShowLyric(!showLyric);
                      }} />

                    </div>

                    {/* <div className='ring-phone'>
                      <img src={require('../assets/icon/ic_ring_phone.svg')} width={15} className="" onClick={() => setShowRingPhone(!showRingPhone)} />
                      {
                        showRingPhone
                          ? <>
                              <RingPhone onBack={setShowRingPhone} currentSong={playList.playList[playList.currentMusicIndex]} />
                            </>
                          : null
                      }
                    </div> */}


                  </div>
                }

              </div>
            </Wrapper >
            <div className={showLyric == true ? 'display-block lyric-footer ' : 'display-none'}>
              <img src={songLyric ? songLyric.avatar : ''} style={{ width: '30%', height: 'auto', marginLeft: '10%' }} />
              <ScrollbarLyric>
                {songLyric ?
                  <>
                    <div className="footer-lyrics">
                      {songLyric.lyric != "" ? songLyric.lyric : t('none-lyrics')}
                    </div>
                    <div className="footer-lyrics-close-btn-mobile" onClick={() => {
                      setShowLyric(!showLyric);
                    }} >
                      <img src={require("../assets/icon/ic_back.png")} />
                    </div>
                  </>
                  :
                  <>
                    <div className="footer-lyrics">
                      {t('none-lyrics')}
                    </div>
                    <div className="footer-lyrics-close-btn-mobile" onClick={() => {
                      setShowLyric(!showLyric);
                    }} >
                      &gt;
                    </div>
                  </>
                }
              </ScrollbarLyric>
            </div>
          </>
          : <Wrapperr>
            <AudioPlayer
              style={{ backgroundColor: '#181818' }}
              autoPlay
              src={''}
            />




          </Wrapperr >
      }
    </>
  );
}


export default Footer;
